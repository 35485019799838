<template>
  <v-container class="m-0 p-0">
    <v-row>
      <v-col>
        <v-tabs height="26" class="mt-0 ms-3 fs-9pt" v-model="tab" background-color="transparent" :color="area.cor">
          <v-tab class="">
            <div class="text-nonecase f-roboto fs-10pt px-0 ms-0 fw-400" style="">
              <i v-i:ic#add-circle#18></i>
              <span class="ms-1">Novo conteúdo</span>
            </div>
          </v-tab>
          <v-tab class="">
            <div class="text-nonecase f-roboto fs-10pt px-0 ms-0 fw-400" style="">
              <i v-i:ic#settings-backup-restore#18></i>
              <span class="ms-1">Extrair do texto original da ementa</span>
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items class="mt-1" v-model="tab">
          <v-tab-item>
            <novoconteudo :area="area" :uc="uc" :key="uc.id+refresh"/>
          </v-tab-item>
          <v-tab-item>
            <ementaviewraw :area="area" :uc="uc" :key="uc.id+refresh"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "adicionarconteudos",
    components: {
      'ementaviewraw': () => import('@/views/cursos/planosv2/ementa/ementa-view-raw.vue'),
      'novoconteudo': () => import('@/views/cursos/planosv2/ementa/novo-conteudo.vue'),
    },
    props: {
      area: { default: null, type: Object },
      uc: { default: null, type: Object },
    },
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
        tab: null,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //self.loading = true;
      //rdb.ref('').on('value',function(snapshot) {
      //  self.algo = snapshot.val();
      //  console.log("self.algo",self.algo);
      //  self.build();
      //});
    },
    methods:{

      build() {
          var self = this;
      }
    }
  }
</script>

<style scoped>
</style>
